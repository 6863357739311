import { keys, reduce, every, some, map, isEmpty } from "lodash-es"

export default {
  components: {
    AppRadioButton: () => import("@/components/elements/AppRadioButton"),
    AppNumberInput: () => import("@/components/elements/AppNumberInput"),
    AppCheckbox: () => import("@/components/elements/AppCheckbox"),
    AppTooltip: () => import("@/components/elements/AppTooltip"),
    CarClassName: () => import("@/components/InventoryManagement/SettingList/CarClassName"),
    SaleStop: () => import("@/components/InventoryManagement/SettingList/LongPeriod/SaleStop"),
    SaleSettings: () => import("@/components/InventoryManagement/SettingList/LongPeriod/SaleSettings")
  },

  props: {
    settings: {
      type: Object,
      default: () => new Object()
    }
  },

  computed: {
    otas() {
      return this.$store.getters.otasWithoutNicolas
    },

    isCommonAddSaleStop({ isSelectedEvery }) {
      return isSelectedEvery("add_sale_stop")
    },

    isCommonDeleteSaleStop({ isSelectedEvery }) {
      return isSelectedEvery("delete_sale_stop")
    },

    isCommonIndeterminatedAddSaleStop({ isSelectedSome, isCommonAddSaleStop }) {
      return isSelectedSome("add_sale_stop") && !isCommonAddSaleStop
    },

    isCommonIndeterminatedDeleteSaleStop({ isSelectedSome, isCommonDeleteSaleStop }) {
      return isSelectedSome("delete_sale_stop") && !isCommonDeleteSaleStop
    }
  },

  methods: {
    isSelectedEvery(field) {
      const arrayOfSelected = map(this.settings, setting => every(setting.sale_settings, item => item[field]))

      return !isEmpty(arrayOfSelected) && arrayOfSelected.every(item => item)
    },

    isSelectedSome(field) {
      return map(this.settings, setting => some(setting.sale_settings, item => item[field])).some(item => item)
    },

    handleChangeSaleSettings(order, change) {
      const setting = { ...this.settings[order], ...change }

      this.sendChangedSettings({ [order]: setting })
    },

    handleChangeSetting(order, change) {
      const setting = { ...this.settings[order], ...change }

      this.sendChangedSettings({ [order]: setting })
    },

    sendChangedSettings(setting) {
      this.$emit("change", { ...this.settings, ...setting })
    },

    setSaleStopForAllCarClasses(value, saleStop = true) {
      const settings = reduce(
        keys(this.settings),
        (settingObj, key) => {
          const setting = this.settings[key]

          settingObj[key] = {
            ...setting,
            sale_settings: this.saleStopsData(value, saleStop)
          }

          return settingObj
        },
        {}
      )
      this.$emit("change", settings)
    },

    saleStopsData(value, saleStop) {
      return reduce(
        this.otas,
        (obj, { id }) => {
          obj[id] = {
            add_sale_stop: saleStop ? value : false,
            delete_sale_stop: saleStop ? false : value
          }

          return obj
        },
        {}
      )
    }
  }
}
